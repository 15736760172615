@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Acme&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@keyframes slide-up {
  0% {
    visibility: visible;
    top: 18rem;
  }
  100% {
    visibility: visible;
    top: 13rem;
  }
}

#page-header3 {
  position: relative;
  width: 100%;
  height: 40vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 14px;
}

@media (max-width: 1024px) { 
  #page-header3 {
    height: 20vh; 
  }
}

#page-header3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 30%;
  z-index: 1; 
}

#page-header3 h2,
#page-header3 p {
  color: #fff;
  position: relative; 
  z-index: 2;
}

.img {
  border-radius: 6px !important; 
}

/* From Uiverse.io by SujitAdroja */ 
/* From Uiverse.io by adeladel522 */ 
.button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: rgb(0 107 179);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  /* border: 2px solid #ffffff4d; */
  outline: none;
  overflow: hidden;
  font-size: 15px;
}

.icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.button:hover .icon {
  transform: translate(4px);
}

.button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}
